import { Channel } from "./interfaces/channel";
import { EventEmitter } from "events";
import { log } from "./logger";
import ws from "ws";

class WebSocketChannel extends EventEmitter implements Channel {
  private readonly WebSocket;
  private socket: WebSocket | null = null;

  constructor(private readonly url: string) {
    super();
    this.url = url;
    this.WebSocket = global["WebSocket"] || global["MozWebSocket"] || ws;
  }

  public get isConnected(): boolean {
    return !!this.socket && this.socket.readyState === 1;
  }

  public connect(): void {
    log.trace("connecting to socket");

    let socket;

    try {
      socket = new this.WebSocket(this.url);
    } catch (e) {
      log.debug(`Socket error: ${this.url}`);
      this.emit("socketError", e);
      return;
    }

    socket.binaryType = "arraybuffer";

    socket.onopen = () => {
      log.debug(`socket opened ${this.url}`);
      this.emit("connected");
    };

    socket.onclose = (e) => {
      log.debug("socket closed", e);
      this.emit("disconnected", e);
    };

    socket.onerror = (e) => {
      log.debug("Socket error:", e);
      this.emit("socketError", e);
    };

    socket.onmessage = (message: MessageEvent) => {
      this.emit("message", message.data);
    };

    this.socket = socket;
  }

  public send(message: ArrayBuffer): void | null {
    return this.socket && this.socket.send(message);
  }

  close(): void {
    log.trace("closing socket");

    if (this.socket) {
      this.socket.onopen = null;
      this.socket.onclose = null;
      this.socket.onerror = null;
      this.socket.onmessage = null;
      try {
        this.socket.close();
      } finally {
      }
    }
  }
}

export { WebSocketChannel };
