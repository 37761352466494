import * as platform from "platform";

class Metadata {
  public static getMetadata(options: {
    clientMetadata: Metadata;
  }): Record<string, unknown> {
    const overrides =
      options && options.clientMetadata ? options.clientMetadata : {};

    const fieldNames = [
      "ver",
      "env",
      "envv",
      "os",
      "osv",
      "osa",
      "type",
      "sdk",
      "sdkv",
      "dev",
      "devv",
      "devt",
      "app",
      "appv",
    ];
    const defaults = {
      env: platform.name,
      envv: platform.version,
      os: platform.os?.family,
      osv: platform.os?.version,
      osa: platform.os?.architecture,
      sdk: "js-default",
    };

    const finalClientMetadata = {};
    fieldNames
      .filter((key) => key in overrides || key in defaults)
      .forEach(
        (key) =>
          (finalClientMetadata[key] =
            key in overrides ? overrides[key] : defaults[key])
      );

    return finalClientMetadata;
  }
}

export { Metadata };
