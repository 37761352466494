import { EventEmitter } from "events";
import * as StateMachine from "javascript-state-machine";
import { Configuration } from "./configuration";
import { log } from "./logger";
import { ConnectionState } from "./client";
import type { Header } from "./protocol/protocol";
import * as Messages from "./protocol/messages";
import { Parser } from "./parser";
import { PacketInterface } from "./packetinterface";
import { WebSocketChannel } from "./websocketchannel";
import { TwilsockReplyError } from "./error/twilsockreplyerror";
import { BackoffRetrier } from "./backoffretrier";
import type { ReplyType } from "./protocol/messages/reply";

const DISCONNECTING_TIMEOUT = 3000;

// Wraps asynchronous rescheduling
// Just makes it simpler to find these hacks over the code
// Currently there's exactly one instance of this in closeSocket()
function trampoline(f: () => void) {
  setTimeout(f, 0);
}

class Response {}

/**
 * Twilsock channel level protocol implementation
 */
class TwilsockChannel extends EventEmitter {
  private readonly config: Configuration;

  private readonly fsm: StateMachine;

  private disconnectingTimer: ReturnType<typeof setTimeout> | null = null;

  private disconnectedPromiseResolve: (() => void) | null = null;
  private retrier: BackoffRetrier;

  private websocket: WebSocketChannel;
  private transport: PacketInterface;

  private readonly terminalStates: Array<string> = ["disconnected", "rejected"];
  private lastEmittedState;
  private readonly tokenExpiredSasCode = 20104;

  private terminationReason = "Connection is not initialized";

  constructor(
    websocket: WebSocketChannel,
    transport: PacketInterface,
    config: Configuration
  ) {
    super();

    this.websocket = websocket;
    this.websocket.on("connected", () => this.fsm.socketConnected());
    this.websocket.on("disconnected", () => this.fsm.socketClosed());
    this.websocket.on("message", (message) => this.onIncomingMessage(message));
    this.websocket.on("socketError", (e) =>
      this.emit("connectionError", {
        terminal: false,
        message: `Socket error: ${e.message}`,
        httpStatusCode: null,
        errorCode: null,
      })
    );

    this.transport = transport;
    this.config = config;

    this.retrier = new BackoffRetrier(config.retryPolicy);
    this.retrier.on("attempt", () => this.retry());
    this.retrier.on("failed", (err) => {
      log.warn(`Retrying failed: ${err.message}`);
      this.disconnect();
    });

    if (
      typeof window !== "undefined" &&
      typeof window.addEventListener !== "undefined"
    ) {
      window.addEventListener("online", () => {
        log.debug("Browser reported connectivity state: online");
        this.resetBackoff();
        this.fsm.systemOnline();
      });

      window.addEventListener("offline", () => {
        log.debug("Browser reported connectivity state: offline");
        this.websocket.close();
        this.fsm.socketClosed();
      });
    }

    // We have to use a factory function in here because using the default
    // StateMachine constructor would cause an error for the consumers of the
    // rollup bundles. This is a quirk unique to the javascript-state-machine
    // library.

    const TwilsockStateMachine: StateMachine = StateMachine.factory({
      init: "disconnected",
      transitions: [
        {
          name: "userConnect",
          from: ["disconnected", "rejected"],
          to: "connecting",
        },
        { name: "userConnect", from: ["connecting", "connected"] }, // ignore event
        {
          name: "userDisconnect",
          from: [
            "connecting",
            "initialising",
            "connected",
            "updating",
            "retrying",
            "rejected",
            "waitSocketClosed",
            "waitOffloadSocketClosed",
          ],
          to: "disconnecting",
        },
        { name: "userRetry", from: ["retrying"], to: "connecting" },
        { name: "socketConnected", from: ["connecting"], to: "initialising" },
        {
          name: "socketClosed",
          from: [
            "connecting",
            "initialising",
            "connected",
            "updating",
            "error",
            "waitOffloadSocketClosed",
          ],
          to: "retrying",
        },
        { name: "socketClosed", from: ["disconnecting"], to: "disconnected" },
        {
          name: "socketClosed",
          from: ["waitSocketClosed"],
          to: "disconnected",
        },
        { name: "socketClosed", from: ["rejected"], to: "rejected" },
        { name: "initSuccess", from: ["initialising"], to: "connected" },
        { name: "initError", from: ["initialising"], to: "error" },
        {
          name: "tokenRejected",
          from: ["initialising", "updating"],
          to: "rejected",
        },
        {
          name: "protocolError",
          from: ["initialising", "connected", "updating"],
          to: "error",
        },
        {
          name: "receiveClose",
          from: ["initialising", "connected", "updating"],
          to: "waitSocketClosed",
        },
        {
          name: "receiveOffload",
          from: ["initialising", "connected", "updating"],
          to: "waitOffloadSocketClosed",
        },
        {
          name: "unsupportedProtocol",
          from: ["initialising", "connected", "updating"],
          to: "unsupported",
        },
        {
          name: "receiveFatalClose",
          from: ["initialising", "connected", "updating"],
          to: "unsupported",
        },
        {
          name: "userUpdateToken",
          from: ["disconnected", "rejected", "connecting", "retrying"],
          to: "connecting",
        },
        { name: "userUpdateToken", from: ["connected"], to: "updating" },
        { name: "updateSuccess", from: ["updating"], to: "connected" },
        { name: "updateError", from: ["updating"], to: "error" },
        { name: "userSend", from: ["connected"], to: "connected" },
        { name: "systemOnline", from: ["retrying"], to: "connecting" },
      ],
      methods: {
        onConnecting: () => {
          this.setupSocket();
          this.emit("connecting");
        },
        onEnterInitialising: () => {
          this.sendInit();
        },
        onLeaveInitialising: () => {
          this.cancelInit();
        },
        onEnterUpdating: () => {
          this.sendUpdate();
        },
        onLeaveUpdating: () => {
          this.cancelUpdate();
        },
        onEnterRetrying: () => {
          this.initRetry();
          this.emit("connecting");
        },
        onEnterConnected: () => {
          this.resetBackoff();
          this.onConnected();
        },
        onUserUpdateToken: () => {
          this.resetBackoff();
        },
        onTokenRejected: () => {
          this.resetBackoff();
          this.closeSocket(true);
          this.finalizeSocket();
        },
        onUserDisconnect: () => {
          this.closeSocket(true);
        },
        onEnterDisconnecting: () => {
          this.startDisconnectTimer();
        },
        onLeaveDisconnecting: () => {
          this.cancelDisconnectTimer();
        },
        onEnterWaitSocketClosed: () => {
          this.startDisconnectTimer();
        },
        onLeaveWaitSocketClosed: () => {
          this.cancelDisconnectTimer();
        },
        onEnterWaitOffloadSocketClosed: () => {
          this.startDisconnectTimer();
        },
        onLeaveWaitOffloadSocketClosed: () => {
          this.cancelDisconnectTimer();
        },
        onDisconnected: () => {
          this.resetBackoff();
          this.finalizeSocket();
        },
        onReceiveClose: () => {
          this.onCloseReceived();
        },
        onReceiveOffload: (event, args) => {
          log.debug("onreceiveoffload: ", args);
          this.modifyBackoff(args.body);
          this.onCloseReceived();
        },
        onUnsupported: () => {
          this.closeSocket(true);
          this.finalizeSocket();
        },
        onError: (lifecycle, graceful: boolean) => {
          this.closeSocket(graceful);
          this.finalizeSocket();
        },
        onEnterState: (event) => {
          if (event.from !== "none") {
            this.changeState(event);
          }
        },
        onInvalidTransition: (transition, from, to) => {
          log.warn("FSM: unexpected transition", from, to);
        },
      },
    });
    this.fsm = new TwilsockStateMachine();
  }

  private changeState(event: {
    transition: unknown;
    from: string;
    to: string;
  }): void {
    log.debug(`FSM: ${event.transition}: ${event.from} --> ${event.to}`);

    if (this.lastEmittedState !== this.state) {
      this.lastEmittedState = this.state;
      this.emit("stateChanged", this.state);
    }
  }

  private resetBackoff(): void {
    log.trace("resetBackoff");
    this.retrier.stop();
  }

  private modifyBackoff(body): void {
    log.trace("modifyBackoff", body);

    const backoffPolicy = body ? body.backoff_policy : null;

    if (backoffPolicy && typeof backoffPolicy.reconnect_min_ms === "number") {
      this.retrier.modifyBackoff(backoffPolicy.reconnect_min_ms);
    }
  }

  private startDisconnectTimer(): void {
    log.trace("startDisconnectTimer");

    if (this.disconnectingTimer) {
      clearTimeout(this.disconnectingTimer);
      this.disconnectingTimer = null;
    }

    this.disconnectingTimer = setTimeout(() => {
      log.debug("disconnecting is timed out");
      this.closeSocket(true);
    }, DISCONNECTING_TIMEOUT);
  }

  private cancelDisconnectTimer(): void {
    log.trace("cancelDisconnectTimer");

    if (this.disconnectingTimer) {
      clearTimeout(this.disconnectingTimer);
      this.disconnectingTimer = null;
    }
  }

  public get isConnected(): boolean {
    return this.state === "connected" && this.websocket.isConnected;
  }

  public get state(): ConnectionState {
    switch (this.fsm.state) {
      case "connecting":
      case "initialising":
      case "retrying":
      case "error":
        return "connecting";
      case "updating":
      case "connected":
        return "connected";
      case "rejected":
        return "denied";
      case "disconnecting":
      case "waitSocketClosed":
      case "waitOffloadSocketClosed":
        return "disconnecting";
      case "disconnected":
      default:
        return "disconnected";
    }
  }

  private initRetry() {
    log.debug("initRetry");
    if (this.retrier.inProgress) {
      this.retrier.attemptFailed();
    } else {
      this.retrier.start();
    }
  }

  private retry(): void {
    if (this.fsm.state != "connecting") {
      log.trace("retry");
      this.websocket.close();
      this.fsm.userRetry();
    } else {
      log.trace("can\t retry as already connecting");
    }
  }

  private onConnected(): void {
    this.emit("connected");
  }

  private finalizeSocket(): void {
    log.trace("finalizeSocket");

    this.websocket.close();
    this.emit("disconnected");

    if (this.disconnectedPromiseResolve) {
      this.disconnectedPromiseResolve();
      this.disconnectedPromiseResolve = null;
    }
  }

  private setupSocket(): void {
    log.trace("setupSocket:", this.config.token);
    this.emit("beforeConnect"); // This is used by client to record startup telemetry event
    this.websocket.connect();
  }

  private onIncomingMessage(message: ArrayBuffer): void {
    const parsedMessage = Parser.parse(message);
    if (!parsedMessage) {
      return;
    }
    const { method, header, payload } = parsedMessage;

    if (method !== "reply") {
      this.confirmReceiving(header);
    }

    if (method === "notification") {
      this.emit("message", header.message_type, payload);
    } else if (header.method === "reply") {
      this.transport.processReply({
        id: header.id,
        status: header.status,
        header: header,
        body: payload,
      });
    } else if (header.method === "client_update") {
      if (header.client_update_type === "token_about_to_expire") {
        this.emit("tokenAboutToExpire");
      }
    } else if (header.method === "close") {
      if (header.status.code === 308) {
        log.debug("Connection has been offloaded");
        this.fsm.receiveOffload({
          status: header.status.status,
          body: payload,
        });
      } else if (header.status.code === 406) {
        // Not acceptable message
        const message = `Server closed connection because can't parse protocol: ${JSON.stringify(
          header.status
        )}`;
        this.emitReplyConnectionError(message, header, true);
        log.error(message);
        this.fsm.receiveFatalClose();
      } else if (header.status.code === 417) {
        // Protocol error
        log.error(
          `Server closed connection because can't parse client reply: ${JSON.stringify(
            header.status
          )}`
        );
        this.fsm.receiveFatalClose(header.status.status);
      } else if (header.status.code === 410) {
        // Expired token
        log.warn(`Server closed connection: ${JSON.stringify(header.status)}`);
        this.fsm.receiveClose(header.status.status);
        this.emit("tokenExpired");
      } else if (header.status.code === 401) {
        // Authentication fail
        log.error(`Server closed connection: ${JSON.stringify(header.status)}`);
        this.fsm.receiveClose(header.status.status);
      } else {
        log.warn("unexpected message: ", header.status);
        // Try to reconnect
        this.fsm.receiveOffload({ status: header.status.status, body: null });
      }
    }
  }

  private async sendInit(): Promise<void> {
    log.trace("sendInit");

    try {
      this.emit("beforeSendInit"); // This is used by client to record startup telemetry event
      const reply = await this.transport.sendInit();
      this.config.updateContinuationToken(reply.continuationToken);
      this.config.confirmedCapabilities = reply.confirmedCapabilities;
      this.fsm.initSuccess(reply);
      this.emit("initialized", reply);
      this.emit("tokenUpdated");
    } catch (ex) {
      if (ex instanceof TwilsockReplyError) {
        let isTerminalError = false;

        log.warn(`Init rejected by server: ${JSON.stringify(ex.reply.status)}`);
        this.emit("sendInitFailed"); // This is used by client to record startup telemetry event
        // @todo emit telemetry from inside "if" below for more granularity...

        if (ex.reply.status.code === 401 || ex.reply.status.code === 403) {
          isTerminalError = true;
          this.fsm.tokenRejected(ex.reply.status);
          if (ex.reply.status.errorCode === this.tokenExpiredSasCode) {
            this.emit("tokenExpired");
          }
        } else if (ex.reply.status.code === 429) {
          this.modifyBackoff(ex.reply.body);
          this.fsm.initError(true);
        } else if (ex.reply.status.code === 500) {
          this.fsm.initError(false);
        } else {
          this.fsm.initError(true);
        }

        this.emitReplyConnectionError(ex.message, ex.reply, isTerminalError);
      } else {
        this.terminationReason = ex.message;
        this.emit("connectionError", {
          terminal: true,
          message: `Unknown error during connection initialisation: ${
            ex.message
          }\n${JSON.stringify(ex, null, 2)}`,
          httpStatusCode: null,
          errorCode: null,
        });
        this.fsm.initError(true);
      }
      this.emit("tokenUpdated", ex);
    }
  }

  private async sendUpdate(): Promise<void> {
    log.trace("sendUpdate");

    const message = new Messages.Update(this.config.token);

    try {
      const reply = await this.transport.sendWithReply(message);
      this.fsm.updateSuccess(reply.body);
      this.emit("tokenUpdated");
    } catch (ex) {
      if (ex instanceof TwilsockReplyError) {
        let isTerminalError = false;
        log.warn(
          `Token update rejected by server: ${JSON.stringify(ex.reply.status)}`
        );
        if (ex.reply.status.code === 401 || ex.reply.status.code === 403) {
          isTerminalError = true;
          this.fsm.tokenRejected(ex.reply.status);
          if (ex.reply.status.errorCode === this.tokenExpiredSasCode) {
            this.emit("tokenExpired");
          }
        } else if (ex.reply.status.code === 429) {
          this.modifyBackoff(ex.reply.body);
          this.fsm.updateError(ex.reply.status);
        } else {
          this.fsm.updateError(ex.reply.status);
        }
        this.emitReplyConnectionError(ex.message, ex.reply, isTerminalError);
      } else {
        this.emit("error", false, ex.message, null, null);
        this.fsm.updateError(ex);
      }
      this.emit("tokenUpdated", ex);
    }
  }

  private emitReplyConnectionError(
    message: string,
    header: Header | ReplyType,
    terminal: boolean
  ) {
    const description =
      header.status && header.status.description
        ? header.status.description
        : message;

    const httpStatusCode = header.status.code;

    const errorCode =
      header.status && header.status.errorCode ? header.status.errorCode : null;

    if (terminal) {
      this.terminationReason = description;
    }

    this.emit("connectionError", {
      terminal: terminal,
      message: `Connection error: ${description}`,
      httpStatusCode: httpStatusCode,
      errorCode: errorCode,
    });
  }

  private cancelInit(): void {
    log.trace("cancelInit");
    // TODO: implement
  }

  private cancelUpdate(): void {
    log.trace("cancelUpdate");
    // TODO: implement
  }

  /**
   * Should be called for each message to confirm it received
   */

  private confirmReceiving(messageHeader: Header) {
    log.trace("confirmReceiving");

    try {
      //@todo send telemetry events AnyEvents
      this.transport.send(new Messages.Reply(messageHeader.id));
    } catch (e) {
      log.debug("failed to confirm packet receiving", e);
    }
  }

  /**
   * Shutdown connection
   */
  private closeSocket(graceful: boolean): void {
    log.trace(`closeSocket (graceful: ${graceful})`);

    if (graceful && this.transport.isConnected) {
      this.transport.sendClose();
    }
    this.websocket.close();

    trampoline(() => this.fsm.socketClosed());
  }

  /**
   * Initiate the twilsock connection
   * If already connected, it does nothing
   */
  connect(): void {
    log.trace("connect");
    this.fsm.userConnect();
  }

  /**
   * Close twilsock connection
   * If already disconnected, it does nothing
   */
  disconnect(): Promise<void> {
    log.trace("disconnect");

    if (this.fsm.is("disconnected")) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.disconnectedPromiseResolve = resolve;
      this.fsm.userDisconnect();
    });
  }

  /**
   * Update fpa token for twilsock connection
   */
  public updateToken(token: string): Promise<void> {
    log.trace("updateToken:", token);

    return new Promise((resolve, reject) => {
      this.once("tokenUpdated", (e) => {
        if (e) {
          reject(e);
        } else {
          resolve();
        }
      });

      this.fsm.userUpdateToken();
    });
  }

  public get isTerminalState(): boolean {
    return this.terminalStates.indexOf(this.fsm.state) !== -1;
  }

  public get getTerminationReason(): string {
    return this.terminationReason;
  }

  private onCloseReceived(): void {
    this.websocket.close();
  }
}

export { Response, TwilsockChannel, TwilsockChannel as TwilsockImpl };
